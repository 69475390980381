import { createRouter, createWebHashHistory } from "vue-router";
// import { createRouter,createWebHistory } from "vue-router";

const routes = [
  {
    path: "/share",
    name: "share",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/share.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(), // Hash模式
  // history: createWebHistory('/'),// history模式
  routes,
});

export default router;
