<template>
  <router-view />
</template>

<style>
@import "./common/public.css";
* {
  margin: 0;
  padding: 0;
}
</style>
